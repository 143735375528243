import React from 'react';

import MainAppContainer from './containers/MainAppContainer';

function App() {
	return (
		<div>
			<MainAppContainer />
		</div>
	);
}

export default App;
